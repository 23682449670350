import React from 'react';
//gatsby
import { Link, graphql } from 'gatsby';
//gatsby image
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//router
import { useLocation } from '@reach/router';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";
//share
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";

//styled
const ArticleWrap = styled.section`
  padding-top: ${rem('60px')};
`;

const ArticleBack = styled.div`
  position: relative;
  z-index: 3;
  @media (max-width: ${vars.media.smMax}) {
    text-align: center;
    margin-bottom: ${rem('20px')};
  }
  .button {
    svg {
      margin-right: ${rem('10px')};
    }
  }
`;

const ArticleHeader = styled.div`
  margin-bottom: ${rem('60px')};
  text-align: center;
  @media (min-width: ${vars.media.mdMin}) {
    margin-top: ${rem('-38px')};
  }
  .blog {
    font-size: ${rem('24px')};
    color: ${vars.colors.capri};
    font-weight: 800;
    margin-bottom: ${rem('20px')};
  }
  .date {
    margin-bottom: ${rem('20px')};
  }
  .image-socials {
    position: relative;
  }
  .img-wrap {
    height: 520px;
    background-color: ${vars.colors.grey1};
    border-radius: ${rem('12px')};
    overflow: hidden;
    margin-bottom: ${rem('32px')};
    .gatsby-image-wrapper {
      display: block;
    }
    img {
      width: 100%;
      height: 520px;
      border-radius: ${rem('12px')};
      object-fit: cover;
      object-position: center;
      transform: translateZ(0)!important;
    }
  }
  .social-share {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${vars.media.xlMin}) {
      flex-direction: column;
      position: absolute;
      margin: auto;
      top: 0;
      right: ${rem('-87px')};
      bottom: 0;
    }
    li {
      margin-left: ${rem('12px')};
      margin-right: ${rem('12px')};
      @media (min-width: ${vars.media.xlMin}) {
        &:not(:last-child) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: ${rem('28px')};
        }
      }
      a,
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem('24px')};
        height: ${rem('24px')};
        svg {
          transition: all .2s ease-in-out;
        }
        &:hover {
          color: ${vars.colors.capri};
          svg {
            fill: ${vars.colors.capri}!important;
          }
        }
      }
    }
  }
`;

const ArticleContent = styled.article`
  position: relative;
  padding-bottom: ${rem('60px')};
`;

const ArticleBottom = styled.div`
  padding-bottom: ${rem('70px')};
  .card {
    background-color: ${vars.colors.grey1};
    padding: ${rem('40px')} ${rem('20px')};
    text-align: center;
    border-radius: 12px;
    margin-bottom: ${rem('30px')};
    .social-share {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        margin-left: ${rem('12px')};
        margin-right: ${rem('12px')};
        a,
        button {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${rem('24px')};
          height: ${rem('24px')};
          svg {
            transition: all .2s ease-in-out;
          }
          &:hover {
            color: ${vars.colors.capri};
            svg {
              fill: ${vars.colors.capri}!important;
            }
          }
        }
      }
    }
    .author {
      display: block;
      &:hover {
        h5 {
          text-decoration: underline;
        }
      }
      .img-wrap {
        width: ${rem('120px')};
        height: ${rem('120px')};
        border-radius: 50%;
        background-color: ${vars.colors.grey2};
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${rem('16px')};
        overflow: hidden;
        img {
          width: ${rem('120px')};
          height: ${rem('120px')};
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
`;

const ArticleRelated = styled.div`
  padding-top: ${rem('100px')};
  padding-bottom: ${rem('100px')};
  background-color: ${vars.colors.grey1};
  h2 {
    text-align: center;
    margin-bottom: ${rem('60px')};
  }
`;

const ArticleCTA = styled.div`
  position: relative;
`;

const Article = ({ data }) => {

  const article = data.article;
  const author = data.article.author.node;
  const relatedArticles = data.relatedArticles.nodes;

  //get current url
  const location = useLocation();
  const currentURL = location.href;

  const copyUrl = () => {
    if (typeof window !== `undefined`) {
      const el = document.createElement('input');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Article Link Copied!');
    }
  };

  return (
    <Layout
      isResources={true}
      headerBg={'#ffffff'}
      headerColor={vars.colors.darkBlue}
    >
      <Seo post={article} />
      <ArticleWrap>
        <ArticleBack>
          <Container>
            <Button className="button" to="/learn" sm>
              <Icon icon="arrow-circle-left" size={18} /> Back To Blog
            </Button>
          </Container>
        </ArticleBack>
        <ArticleHeader>
          <Container maxWidth="848px">
            <p className="blog">Blog</p>
            <Heading heading2>{article.title}</Heading>
            <p className="date">{article.date}</p>
            <div className="image-socials">
              <div className="img-wrap">
                {article.featuredImage &&
                  <GatsbyImage image={getImage(article.featuredImage.node.localFile.childImageSharp)} alt={article.title} />
                }
              </div>
              <ul className="social-share">
                <li>
                  <TwitterShareButton
                    url={`${currentURL}${article.uri}`}
                    title={article.title}>
                    <Icon icon="twitter" size={20} />
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton
                    url={`${currentURL}${article.uri}`}
                    quote={article.title}>
                    <Icon icon="facebook" size={20} />
                  </FacebookShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    url={`${currentURL}${article.uri}`}
                    title={article.title}>
                    <Icon icon="linkedin" size={20} />
                  </LinkedinShareButton>
                </li>
                <li>
                  <button onClick={copyUrl}>
                    <Icon icon="link" size={20} />
                  </button>
                </li>
              </ul>
            </div>
          </Container>
        </ArticleHeader>
        <ArticleContent>
          <Container maxWidth="848px">
            <div className="wp-front">
              {!!article.content && (
                parse(article.content)
              )}
            </div>
          </Container>
        </ArticleContent>
        <ArticleBottom>
          <Container maxWidth="848px">
            <Row>
              <Col col xs={12} sm={6}>
                <div className="card">
                  <Heading as="h4" heading6 marginBottom="30px">Share this article</Heading>
                  <ul className="social-share">
                    <li>
                      <TwitterShareButton
                        url={`${currentURL}${article.uri}`}
                        title={article.title}>
                        <Icon icon="twitter" size={20} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={`${currentURL}${article.uri}`}
                        quote={article.title}>
                        <Icon icon="facebook" size={20} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton
                        url={`${currentURL}${article.uri}`}
                        title={article.title}>
                        <Icon icon="linkedin" size={20} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <button onClick={copyUrl}>
                        <Icon icon="link" size={20} />
                      </button>
                    </li>
                  </ul>
                </div>
                
              </Col>
              <Col col xs={12} sm={6}>
                <div className="card">
                  <Heading as="h4" heading6 marginBottom="16px">Written by</Heading>
                  <Link to={author.uri} className="author">
                    <div className="img-wrap">
                      {author.avatar.url && <img src={author.avatar.url} alt={`${author.firstName} ${author.lastName}`} />}
                    </div>
                    <Heading as="h5" heading7 marginBottom="0px">{`${author.firstName} ${author.lastName}`}</Heading>
                    <p>{author.userTitleRole}</p>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </ArticleBottom>
        <ArticleRelated>
          <Container>
            <Heading as="h2" heading2>Related Articles</Heading>
            <Row>
              {relatedArticles.map((article) => (
                <Col col xs={12} sm={4} key={article.id}>
                  <Small post={article} />
                </Col>
              ))}
            </Row>
          </Container>
        </ArticleRelated>
        <ArticleCTA className="wp-front">
          {!!data.wp.blogCtas.articlesCTA && (
            parse(data.wp.blogCtas.articlesCTA)
          )}
        </ArticleCTA>
      </ArticleWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
query ArticleById( $id: String!, $category: String!) {
    article: wpArticle(id: { eq: $id }) {
      id
      content
      title
      nodeType
      date(formatString: "MMMM DD, YYYY")
      uri
      articleCategories {
        nodes {
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 816, quality: 100)
            }
          }
        }
      }
      author {
        node {
          uri
          firstName
          lastName
          userTitleRole
          avatar {
            url
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      articleCategories {
        nodes {
          id
          name
          uri
        }
      }
    }
    relatedArticles: allWpArticle(
      sort: {fields: date, order: DESC},
      limit: 3,
      filter: {id: {ne: $id}, articleCategories: {nodes: {elemMatch: {slug: {eq: $category}}}}, status: {eq: "publish"}}
      ) {
      nodes {
        id
        title
        uri
        excerpt
        nodeType
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 826, quality: 100)
              }
            }
          }
        }
        articleCategories {
          nodes {
            uri
            name
            id
          }
        }
      }
    }
    wp {
      blogCtas {
        articlesCTA
      }
    }
  }
`

export default Article;